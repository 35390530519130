<template>
  <div class="main gray-bg" :class="query.status == 4 ? ' has-bottom' : ''">
    <van-nav-bar class="top-bar" title="申请详情" left-arrow @click-left="onClickLeft"/>
    <van-form class="label-form" v-model:loading="loading">
      <van-cell-group>
        <van-field label="游戏名称：" :model-value="query.game_name" readonly/>
        <van-field label="选择小号：" :model-value="query.small_account" readonly/>
        <van-field label="游戏区服：" :model-value="query.server_name" readonly/>
        <van-field label="角色ID：" :model-value="query.game_player_id" readonly/>
        <van-field label="角色名称：" :model-value="query.game_player_name" readonly/>
        <van-field label="充值时间：" :model-value="query.recharge_time" readonly/>
        <van-field label="充值金额：" :model-value="query.recharge_amount" readonly/>
        <van-field label="申请内容:" label-align="top" :model-value="query.content" rows="2" autosize type="textarea"
                   placeholder="请填写所需申请活动内容及道具" readonly/>
        <div class="van-cell card-block-item">
          <div class="label">申请状态：</div>
          <div class="value van-ellipsis">
            <span :style="{'color':typeFormat(query.status,statusConfig.status_style)}">{{typeFormat(query.status,statusConfig.status_text)}}</span>
          </div>
          <van-tag v-if="query.status == 3" style="cursor: pointer" color="#4983FE" @click.stop="asyncCopy(query.remark)" plain round>一键复制</van-tag>
        </div>
        <van-field class="card-remark" v-if="query.status == 3 || query.status == 4" label-align="top" rows="2" :model-value="query.remark" autosize type="textarea" readonly/>
      </van-cell-group>
    </van-form>
    <!--底部按钮-->
    <div class="bottom-bar" v-if="query.status == 4">
      <van-button type="primary" round block :to="'/personal/activity-rebate/'+`${query.active_id}/${query.game_name}/${params.ids}`">重新申请</van-button>
    </div>
  </div>
</template>

<script>
import {Button, CellGroup, Field, Form, NavBar, Tag} from "vant";

export default {
  name: "RebateDetail",
  components: {
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Tag.name]:Tag,
  },
}
</script>
<script setup>
import {date, onClickLeft,typeFormat,asyncCopy} from "@/utils/function";
import {onMounted, reactive, ref} from "vue";
import {rebateDetails} from "@/api/user_active_rebate";
import {useRoute} from "vue-router";

const {params} = useRoute();
const loading = ref(true);
const query = reactive({
  active_id: '',
  game_name: '',
  small_account: '',
  server_name: '',
  game_player_id: '',
  game_player_name: '',
  recharge_time: '',
  content: '',
  recharge_amount: 0,
  status: 1,
  remark: '',
})
const statusConfig = {
  status_text:{
    1:"申请中",
    2:"已审核",
    3:"已发放",
    4:"已驳回"
  },
  status_style:{
    1:"#4983FE",
    2:"#F27E30",
    3:"#13A404",
    4:"#FF0000"
  }
}
onMounted(async () => {
  rebateDetails(params.ids).then((res) => {
    query.game_name = res.data.game_name
    query.recharge_amount = res.data.recharge_amount
    query.active_id = res.data.active_id
    query.small_account = res.data.small_account
    query.server_name = res.data.server_name
    query.game_player_id = res.data.game_player_id
    query.game_player_name = res.data.game_player_name
    query.recharge_time = `${date(res.data.start_time)}-${date(res.data.end_time)}`
    query.content = res.data.content
    query.status = res.data.status
    query.remark = res.data.remark
  })
})
</script>

<style scoped>
:deep(.van-nav-bar__text) {
  font-size: 12px;
}
.card-block-item .value{
  flex: 1;
  text-align: left;
}
.card-remark :deep(.van-field__body){
  background: #F7F8FA;
  border-radius: 3px;
  padding:6px 12px;
  font-size: 13px;
  font-weight: 400;
  color: #747C89;
  line-height:24px;
  text-align: left;
}
</style>
